<template>
    <LiefengContent :isBack="true" @backClick="$router.push(`/mobilizationsystem?isLineHome=${$route.query.isLineHome}`)">
        <template v-slot:title>群管理</template>
        <!-- <template v-slot:toolsbarLeft>
      <LiefengTree
        ref="mychild"
        :fixedmenu="true"
        @handleData="getList"
        treeID="mychild"
        fixedContentID="contentarea"
        style="width: 200px"
      ></LiefengTree>
    </template>-->
        <template v-slot:toolsbarRight>
            <!-- <Select style="width:150px" v-model="searchData.groupType">
        <Option :value="item.value" v-for="(item, index) in groupList" :key="index">{{ item.label }}</Option>
      </Select>

      <Button type="primary" icon="ios-search" @click="getList({page:1,pageSize:20})">搜索</Button>
      <Button type="success" icon="ios-refresh" @click="rest">重置</Button> -->

            <Button @click="exportExcel" type="primary" icon="ios-cloud-download-outline">导出</Button>
            <!-- <Button @click="addGroupCode" type="info" icon="ios-add">新增沟通组</Button>
      <Button @click="getOrgQrcode" type="info" icon="ios-add">获取社区二维码</Button> -->
            <Button @click="sendGroupNotice" type="warning" icon="ios-add">发群公告</Button>
            <Button @click="openNoticeTable" type="error">群公告管理</Button>
        </template>
        <template v-slot:contentArea>
            <LiefengTable :talbeColumns="talbeColumns" :tableData="tableData" height="200" :loading="loading" :fixTable="true" :curPage="curPage" :total="total" :page-size="pageSize" :pagesizeOpts="[20, 30, 50, 100]" @hadlePageSize="hadlePageSize"></LiefengTable>
            <!-- 发送群公告 -->

            <LiefengModal title="发送群公告" width="80%" height="calc(100vh - 200px)" :value="sendGroupStatus" @input="sendGroupStatusFn">
                <template v-slot:contentarea>
                    <Collapse v-model="callapseValue" style="margin-top: 20px">
                        <Panel name="1">
                            <span class="validate">选择接收群范围</span>
                            <p slot="content">
                                <span style="margin-bottom: 10px">
                                    <Button type="primary" @click="allSelectGroup" style="margin: 0 10px">全选</Button>
                                    <Button type="primary" @click="deleteSelectGroup" style="margin: 0 10px">清除选择</Button>
                                    <Button type="primary" @click="expandSelectGroup" style="margin: 0 10px">全展开</Button>
                                    <Button type="primary" @click="shrinkSelectGroup" style="margin: 0 10px">全收缩</Button>
                                </span>
                                <Tree :data="groupTreeData" empty-text="暂无群范围" @on-check-change="oneSelectGroupTree" show-checkbox multiple></Tree>
                            </p>
                        </Panel>
                    </Collapse>
                    <Form>
                        <FormItem>
                            <span class="validate" slot="label">公告内容:</span>
                            <Input v-model="content" type="textarea" :rows="4" :maxlength="400" show-word-limit placeholder="请填写内容，限400字" />
                        </FormItem>
                    </Form>

                    <!-- 发送失败弹窗 -->
                    <Modal v-model="sendError" footer-hide :closable="false" :width="400">
                        <h2 style="color: red; text-align: center">发送失败！</h2>
                        <div style="text-align: center; margin: 15px 0 5px">
                            <Button
                                style="margin-right: 16px"
                                @click="
                                    sendError = false
                                    cancelModalTip = false
                                    sendGroupStatusFn(false)
                                "
                            >
                                不发了
                            </Button>
                            <Button type="primary" @click="sendGroup">重新发送</Button>
                        </div>
                    </Modal>
                </template>
                <template v-slot:toolsbar>
                    <Button
                        type="info"
                        style="margin-right: 10px"
                        @click="
                            cancelModalTip = true
                            sendGroupStatusFn(false)
                        "
                    >
                        取消
                    </Button>
                    <Button type="primary" @click="sendGroup">发送</Button>
                </template>
            </LiefengModal>
            <!-- 外部负责人弹窗 -->
            <LiefengModal title="添加管理员" width="800px" :value="outsideModal" @input="outsideModalFn">
                <template v-slot:contentarea>
                    <Header style="background: white; padding: 0px">勾选社区下的管理员，选中则成为管理员，管理员可以对内部管理员和户代表进行管理，对聊天内容进行管理</Header>
                    <Content v-if="manageList">
                        <Button type="primary" @click="allSelectManager">全选</Button>
                        <Form class="selecForm" :model="selecForm" label-position="left" style="display: flex; justify-content: flex-start; flex-wrap: wrap">
                            <FormItem style="width: 30%" v-for="(item, index) in manageList" :key="index">
                                <Checkbox v-model="item.status" @on-change="checkboxChange(item)">{{ item.manage }}</Checkbox>
                            </FormItem>
                        </Form>
                        <Page :pageSize="userpageSize" :pageNum="usercurPage" :total="usertotal" @on-change="getUserPage" v-if="outsideModal"></Page>
                    </Content>
                </template>
            </LiefengModal>
            <!-- 发言管理 -->
            <LiefengModal title="消息详情" :fullscreen="true" :value="msgManageModal" @input="msgManageModalFn">
                <template v-slot:contentarea>
                    <MsgManage :msgManageData="msgManageData" @replyMessageSuccess="hadlePageSize" v-if="msgManageModal"></MsgManage>
                </template>
            </LiefengModal>
            <!-- 群二维码 -->
            <LiefengModal title="群二维码推广" :value="officialStatus" @input="officialStatusFn" :fullscreen="true">
                <template v-slot:contentarea>
                    <h3>该链接可以用于配置到公众号进行推广</h3>
                    <div style="display: flex; justify-content: space-between; line-height: 32px; margin: 14px auto">
                        <span>公众号链接：</span>
                        <Input v-model="officialUrl" search enter-button="复制" style="flex: 1" @on-search="copyShareCode"></Input>
                    </div>
                    <!-- <div
            style="
              display: flex;
              justify-content: space-between;
              line-height: 32px;
              margin: 14px auto;
            "
          >
            <span style="width:84px">短信链接：</span>
            <Input
              v-model="noteUrl"
              search
              enter-button="复制"
              style="flex: 1;margin-right: 100px"
              @on-search="copyNoteUrl"
            ></Input>
          </div> -->
                    <h3>推广二维码已生成，可下载后直接分享二维码到微信（朋友、朋友圈）</h3>
                    <div style="display: flex; align-items: center; justify-content: center">
                        <div class="qrcode" ref="haibaoImg">
                            <div class="h1h3">
                                <h1>网上家园沟通组邀请码</h1>
                                <h3>{{ qrOCdeName | spliceTitle }}</h3>
                            </div>
                            <!-- qrOCdeName -->
                            <p>微信扫码完善信息进入楼栋群</p>
                            <span>{{ communityName }}</span>
                            <img :src="codeImgUrl + '?' + new Date().getTime()" alt ref="qrCode" crossorigin="anonymous" />
                        </div>
                        <div style="width: 130px; height: 100%; margin-left: 30px">
                            <Button type="primary" @click="downloadImgCode" style="margin-bottom: 20px; width: 130px">下载纯二维码</Button>
                            <Button type="warning" @click="mergeImage">下载宣传二维码</Button>
                        </div>
                    </div>
                </template>
                <template v-slot:toolsbar>
                    <Button type="info" @click="officialStatusFn(false)">关闭</Button>
                </template>
            </LiefengModal>
            <LiefengModal :title="addGroupLength ? '新增沟通组' : '修改沟通组'" :value="addGroupStatus" @input="addGroupStatusFn" :fullscreen="true" class="group">
                <template v-slot:contentarea>
                    <Addgroup :formData="formData" :localStree="localStree" :cityOper="cityOper" v-if="isShow" :isShowCheck="isShowCheck" :memberFromScope="memberFromScope" :isEdit="isEdit"></Addgroup>
                </template>
                <template v-slot:toolsbar>
                    <Button
                        type="info"
                        style="margin-right: 10px"
                        @click="
                            canceladdTip = true
                            addGroupStatusFn(false)
                        "
                    >
                        取消
                    </Button>
                    <Button type="primary" @click="saveGroup">确定</Button>
                </template>
            </LiefengModal>
            <LiefengModal title="您可以继续操作" :value="continueStatus" @input="continueStatusFn" :fullscreen="false">
                <template v-slot:contentarea>
                    <div style="padding: 50px; display: flex; justify-content: center">
                        <Button style="margin-right: 5px" type="primary" icon="ios-cloud-upload-outline" @click="importGroup(true)">导入群成员</Button>
                        <!-- <Button
              style="margin-right: 5px"
              type="warning"
              icon="ios-add"
              @click="addGroupNumStatusfn(true)"
            >添加群成员</Button>-->
                        <Button style="margin-right: 5px" type="info" icon="ios-add" @click="addAdmin">添加管理员</Button>
                    </div>
                </template>
            </LiefengModal>
            <LiefengModal title="导入群成员" :value="importStatus" @input="importGroup">
                <template v-slot:contentarea>
                    <Upload
                        ref="excelUpload"
                        type="drag"
                        :action="action"
                        name="file"
                        :show-upload-list="false"
                        :before-upload="beforeExcelUpload"
                        :on-success="uploadSuccess"
                        :on-error="errorUpload"
                        :data="uploadData"
                        :format="['xls', 'xlsx', 'xlsb', 'xlsm', 'xlst']"
                        :on-format-error="uploadFormatError"
                        :headers="headers"
                    >
                        <div style="padding: 20px 0; text-align: center; width: 100%">
                            <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
                            <p>点击上传，或将文件拖拽到此处</p>
                        </div>
                    </Upload>
                    <div v-if="excelFile != null">当前选择的文件名称:{{ excelFile.name }}</div>
                    <!-- <div v-html="errorContent"></div> -->
                    <div style="text-align: center; margin: 20px 0">
                        <Button type="primary" style="margin-right: 20px">
                            <a href="./excelfiles/group.xlsx" download="沟通组导入模板.xlsx" style="color: #fff">模板下载</a>
                        </Button>
                        <Button type="info" @click="submitExcel">确认导入</Button>
                    </div>
                </template>
            </LiefengModal>
            <LiefengModal title="添加群成员" :value="addGroupNumStatus" @input="addGroupNumStatusfn" class="group" :fullscreen="true">
                <template v-slot:contentarea>
                    <Addgroupeople></Addgroupeople>
                </template>
                <template v-slot:toolsbar>
                    <Button
                        type="info"
                        style="margin-right: 10px"
                        @click="
                            canceladdGroupTip = true
                            addGroupNumStatusfn(false)
                        "
                    >
                        取消
                    </Button>
                    <Button type="primary" @click="saveGroupeople">确定</Button>
                </template>
            </LiefengModal>

            <LiefengModal title="修改沟通组" :value="editGroupNumStatus" @input="editGroupNumStatusfn" class="group" :fullscreen="false">
                <template v-slot:contentarea>
                    <Form label-position="left" :label-width="70">
                        <FormItem>
                            <span slot="label" class="validate">群名称:</span>
                            <Input placeholder="限30字" v-model.trim="editData.name" :maxlength="30"></Input>
                        </FormItem>
                    </Form>
                </template>
                <template v-slot:toolsbar>
                    <Button
                        type="info"
                        style="margin-right: 10px"
                        @click="
                            canceleditTip = true
                            editGroupNumStatusfn(false)
                        "
                    >
                        取消
                    </Button>
                    <Button type="primary" @click="saveEditGroup">确定</Button>
                </template>
            </LiefengModal>

            <!--类型为内部管理群的时候-->
            <LiefengModal title="修改沟通组" :value="editGroup.status" @input="editGroupStatusfn" class="group" :fullscreen="false">
                <template v-slot:contentarea>
                    <Form label-position="left" :label-width="70">
                        <FormItem>
                            <span slot="label" class="validate">群名称:</span>
                            <Input placeholder="限30字" v-model.trim="editGroup.name" :maxlength="30"></Input>
                        </FormItem>
                    </Form>
                </template>
                <template v-slot:toolsbar>
                    <Button
                        type="info"
                        style="margin-right: 10px"
                        @click="
                            editGroup.tip = true
                            editGroupStatusfn(false)
                        "
                    >
                        取消
                    </Button>
                    <Button type="primary" @click="editSaveGroup">确定</Button>
                </template>
            </LiefengModal>
            <!--发群公告模态框-->
            <LiefengModal title="发群公告" :value="sendGroupNoticeStatus" @input="sendGroupNoticeStatusfn" class="group" :fullscreen="true">
                <template v-slot:contentarea>
                    <Sendnotice ref="sendChild"></Sendnotice>
                </template>
                <template v-slot:toolsbar>
                    <Button
                        type="info"
                        style="margin-right: 10px"
                        @click="
                            cancelsendGroupNoticeTip = true
                            sendGroupNoticeStatusfn(false)
                        "
                    >
                        取消
                    </Button>
                    <Button type="primary" @click="saveSendGroupNotice">确定</Button>
                </template>
            </LiefengModal>
            <LiefengModal title="获取社区二维码" :value="getOrgCode.status" @input="getOrgCodeStatsFn" class="group" :fullscreen="true">
                <template v-slot:contentarea>
                    <AddQrcode :isOpen="isOpen" :cityOper="qrcodeOpera"></AddQrcode>
                </template>
                <template v-slot:toolsbar>
                    <Button type="primary" @click="closeAddQrcode">关闭</Button>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
//@route("/groupindex")
import LiefengContent from "@/components/LiefengContent3"
import LiefengModal from "@/components/LiefengModal"
import LiefengTable from "@/components/LiefengTable"
import LiefengTree from "@/components/LiefengTree"
import { format } from "@/utils/formatTime.js"
import MsgManage from "@/views/group/childrens/msgmanage"
import html2canvas from "html2canvas"
import Addgroup from "@/views/group/childrens/addgroup"
import Addgroupeople from "@/views/group/childrens/addgroupeople"
import Sendnotice from "@/views/group/childrens/sendnotice"
import AddQrcode from "@/views/group/childrens/addQrcode"
export default {
    data() {
        return {
            searchData: {},
            loading: false,
            //groupId
            groupId: "",
            total: 0,
            status: 2,
            manageList: [],
            rootData: {},
            treeData: [],
            tableData: [],
            talbeColumns: [
                {
                    title: "群范围",
                    minWidth: 130,
                    key: "scopeName",
                    align: "center",
                },
                {
                    title: "群名称",
                    key: "groupName",
                    maxWidth: 150,
                    align: "center",
                },
                {
                    title: "群成员数",
                    key: "memberCount",
                    minWidth: 130,
                    align: "center",
                },
                {
                    title: "创建时间",
                    key: "gmtCreate",
                    align: "center",
                    width: 170,
                },
                {
                    title: "群类型",
                    key: "groupTypeName",
                    align: "center",
                    width: 170,
                },
                {
                    title: "群管理员",
                    align: "center",
                    minWidth: 80,
                    key: "chatGroupManager",
                    // render: (h, params) => {
                    //   var text = "";
                    //   var data = params.row.chatGroupManager;
                    //   for (var i = 0; i < data.length; i++) {
                    //     if (data[i].manager != null) {
                    //       if (text != "") text += "、";
                    //       text += data[i].manager;
                    //     }
                    //   }
                    //   return h("div", [{ text: text }]);
                    // }
                },
                {
                    title: "消息数",
                    key: "chatRecordCount",
                    width: 100,
                    align: "center",
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 100,
                    align: "center",

                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Dropdown",
                                {
                                    props: {
                                        transfer: true,
                                    },
                                },
                                [
                                    h(
                                        "Button",
                                        {
                                            props: {
                                                type: "info",
                                                size: "small",
                                                icon: "ios-arrow-down",
                                            },
                                        },
                                        "编辑"
                                    ),
                                    h(
                                        "DropdownMenu",
                                        {
                                            slot: "list",
                                        },
                                        [
                                            h(
                                                "DropdownItem",
                                                {
                                                    nativeOn: {
                                                        click: () => {
                                                            this.goMsg(params.row)
                                                        },
                                                    },
                                                    style: {
                                                        textAlign: "center",
                                                    },
                                                },
                                                "发言管理"
                                            ),
                                            h(
                                                "DropdownItem",
                                                {
                                                    nativeOn: {
                                                        click: () => {
                                                            this.userMsg(params.row)
                                                            this.selectManagerByGroupId(params.row)
                                                        },
                                                    },
                                                    style: {
                                                        textAlign: "center",
                                                    },
                                                },
                                                "添加管理员"
                                            ),
                                            h(
                                                "DropdownItem",
                                                {
                                                    nativeOn: {
                                                        click: () => {
                                                            let data = params.row
                                                            if (params.row.groupType == "1") {
                                                                this.editGroupNumStatus = true
                                                                this.editData.name = params.row.groupName
                                                                this.isGroupId = data.groupId
                                                            } else if (params.row.groupType == "13" || params.row.groupType == "8" || params.row.groupType == "9") {
                                                                this.editGroup.status = true
                                                                this.editGroup.name = params.row.groupName
                                                                this.editGroupRow = data
                                                            } else if (params.row.groupType == "14") {
                                                                this.editGroup.status = true
                                                                this.editGroup.name = params.row.groupName
                                                                this.editGroupRow = data
                                                                this.isStree = true
                                                            } else {
                                                                this.addGroupLength = false
                                                                this.addGroupStatus = true
                                                                this.isShow = false
                                                                this.$nextTick(() => {
                                                                    this.isShow = true
                                                                })
                                                                this.isEdit = true
                                                                this.memberFromScope = ""

                                                                this.isGroupId = data.groupId
                                                                this.localStree = data.memberFromScope
                                                                this.getUpdateMessage(data.groupId)
                                                            }
                                                        },
                                                    },
                                                },
                                                "修改沟通组"
                                            ),
                                            h(
                                                "DropdownItem",
                                                {
                                                    nativeOn: {
                                                        click: () => {
                                                            this.goContact(params)
                                                        },
                                                    },
                                                    style: {
                                                        textAlign: "center",
                                                    },
                                                },
                                                "群成员管理"
                                            ),
                                            h(
                                                "DropdownItem",
                                                {
                                                    nativeOn: {
                                                        click: () => {
                                                            this.recordMember(params.row.groupId)
                                                        },
                                                    },
                                                    style: {
                                                        textAlign: "center",
                                                        display: params.row.groupType == "9" || params.row.groupType == "8" || params.row.groupType == "14" || params.row.groupType == "13" ? "block" : "none",
                                                    },
                                                },
                                                "同步群成员"
                                            ),
                                            h(
                                                "DropdownItem",
                                                {
                                                    nativeOn: {
                                                        click: () => {
                                                            if (params.row.groupType != "13") {
                                                                this.qrOCdeName = params.row.groupName
                                                                this.openOfficial(params.row)
                                                            } else {
                                                                if (params.row.memberFromScope) {
                                                                    let list = params.row.memberFromScope.split("-")
                                                                    this.qrcodeOpera = {
                                                                        region: list[2],
                                                                        stree: list[3],
                                                                        project: list[4],
                                                                    }
                                                                    if (params.row.communityName) {
                                                                        if (params.row.communityName.indexOf("社区") > -1) {
                                                                            this.qrcodeOpera.qrCodeOrgName = params.row.communityName + "居委会"
                                                                        } else {
                                                                            this.qrcodeOpera.qrCodeOrgName = params.row.communityName + "委会"
                                                                        }
                                                                    } else {
                                                                        this.qrcodeOpera.qrCodeOrgName = ""
                                                                    }

                                                                    console.log(this.qrcodeOpera)
                                                                }
                                                                this.getOrgQrcode()
                                                            }
                                                        },
                                                    },
                                                    style: {
                                                        textAlign: "center",
                                                        display: params.row.groupType == 15 || params.row.groupType == 16 || params.row.groupType == 17 ? "none" : "block",
                                                    },
                                                },
                                                "群二维码"
                                            ),
                                            h(
                                                "DropdownItem",
                                                {
                                                    nativeOn: {
                                                        click: () => {
                                                            this.$Modal.confirm({
                                                                title: "温馨提示",
                                                                content: "删除后将丢失所有消息记录，是否继续操作?",
                                                                onOk: () => {
                                                                    this.$post("/sendmsg/pc/chat/groupCreate/deleteGroup", { groupId: params.row.groupId }).then(res => {
                                                                        if (res.code == 200) {
                                                                            this.$Message.success({
                                                                                content: "删除成功",
                                                                                background: true,
                                                                            })
                                                                        } else {
                                                                            this.$Message.error({
                                                                                content: "删除失败",
                                                                                background: true,
                                                                            })
                                                                        }
                                                                        this.getList({
                                                                            pageSize: this.pageSize,
                                                                            page: this.curPage,
                                                                        })
                                                                    })
                                                                },
                                                            })
                                                        },
                                                    },
                                                    style: {
                                                        textAlign: "center",
                                                    },
                                                },
                                                "删除"
                                            ),
                                        ]
                                    ),
                                ]
                            ),
                        ])
                    },
                },
            ],
            selection: [],
            showDo: true,
            hidenBread: true,
            hideSearch: false,
            curPage: 1,
            pageSize: 20,
            outsideModal: false,
            selecForm: {
                manager: "",
                managerId: "",
                groupId: "",
                oemCode: "",
                operCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                single: false,
            },
            userpageSize: 20,
            usercurPage: 1,
            usertotal: 0,
            treeParams: {
                orgCode: "", //必填
                regionId: "",
                // orgId: "",
                pageSize: 20,
                page: 1,
                oemCode: "", //必填  缓存拿
            },
            //菜单树社区名称和编码
            orgCode: "",
            orgName: "",
            //发言管理
            msgManageData: null,
            msgManageModal: false,

            // 公众号链接
            officialStatus: false,
            officialUrl: "",
            codeImgUrl: "",
            qrCodeList: {}, //二维码参数
            qrCodeOrgName: parent.vue.loginInfo.userinfo.orgName,
            qrOCdeName: "",

            communityProjectCode: "",
            roadCode: "",

            // 发送群公告
            sendGroupStatus: false,
            cancelModalTip: false,
            callapseValue: ["1"], //展开收缩群范围
            groupTreeData: [], //群范围
            groupIds: [],
            content: "",
            sendError: false,

            // zhw新增
            // 显示模态框标题状态
            isAddGroup: false,
            // 是否关闭模态框的控制
            addGroupStatus: false,
            // 取消模态框的状态
            canceladdTip: false,
            isShow: false,
            groupList: [],
            addGroupLength: false,
            formData: {
                name: "",
                type: "0",
                user: "实名",
                ischeck: "校验",
                set: "",
            },
            cityOper: {
                city: "",
                region: "",
                stree: "",
                project: "",
            },
            problem: [],
            // 获取到的行数据id
            isGroupId: "",
            // 绑定修改的地址值
            localStree: "",
            isShowCheck: true,
            memberFromScope: "",
            groupData: "",
            isEdit: false,
            successData: "", //获取成功后返回的groupId
            continueStatus: false,
            importStatus: false,
            // 导入表格的参数
            //excel文件数据
            excelFile: null,
            //导入参数
            uploadData: {},
            errorContent: "",
            action: "",

            // 添加群成员参数
            addGroupNumStatus: false,
            canceladdGroupTip: false,

            // 判断是继续操作的id还是获取的row的id
            idAdmin: false,

            // 修改动员群的模态框状态
            editGroupNumStatus: false,
            canceleditTip: false,

            // 修改edit的表单
            editData: {
                name: "",
            },
            // 控制发群公告模态框
            sendGroupNoticeStatus: false,
            cancelsendGroupNoticeTip: false,
            // 群公告选中的群
            selectList: [],
            sendContent: "",
            // 内部管理群的
            editGroup: {
                status: false,
                tip: false,
                name: "",
            },
            editGroupRow: {},
            // 群二维码模态框数据
            getOrgCode: {
                status: false,
            },
            isOpen: 0,
            // 存放二维码的名称
            communityName: "",
            // 存放二维码城市
            qrcodeOpera: {
                region: "",
                stree: "",
                project: "",
                qrCodeOrgName: "",
            },
            isStree: false,
            headers: { openId: window.sessionStorage.getItem("openId"), Authorization: window.sessionStorage.getItem("accessToken") },

            // 短信链接
            noteUrl: "",
            noteCode: "",
        }
    },
    methods: {
        // zhw新增代码
        // 关闭获取群二维码模态框
        getOrgCodeStatsFn(status) {
            this.getOrgCode.status = status
        },
        closeAddQrcode() {
            this.getOrgCode.status = false
            this.isOpen = 0
        },
        // 点击获取二维码按钮
        getOrgQrcode() {
            this.getOrgCode.status = true
            this.isOpen = 1
        },
        // 点击修改沟通组按钮
        editSaveGroup() {
            // this.editGroup.status = false
            let data = {}

            if (this.isStree) {
                data = {
                    groupId: this.editGroupRow.groupId,
                    groupType: "14",
                    groupName: this.editGroup.name,
                }
            } else {
                if (this.editGroupRow.groupType == "13") {
                    data = {
                        groupId: this.editGroupRow.groupId,
                        groupType: "13",
                        groupName: this.editGroup.name,
                    }
                } else if (this.editGroupRow.groupType == "8") {
                    data = {
                        groupId: this.editGroupRow.groupId,
                        groupType: "8",
                        groupName: this.editGroup.name,
                    }
                } else if (this.editGroupRow.groupType == "9") {
                    data = {
                        groupId: this.editGroupRow.groupId,
                        groupType: "9",
                        groupName: this.editGroup.name,
                    }
                } else {
                    data = {
                        groupId: this.editGroupRow.groupId,
                        groupType: "11",
                        groupName: this.editGroup.name,
                    }
                }
                //  data = {
                //   groupId: this.editGroupRow.groupId,
                //   groupType: "11",
                //   groupName: this.editGroup.name
                // };
            }
            this.updateGroupGrid(data)
        },
        editGroupStatusfn(status) {
            if (!status && this.editGroup.tip) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "您正在关闭此窗口，信息将不会保存，是否继续",
                    onOk: () => {
                        this.editGroup.status = status
                    },
                })
            } else {
                this.editGroup.status = status
                this.editGroup.tip = false
            }
        },
        openNoticeTable() {
            this.$router.push({ path: "/groupnotice" })
        },
        // 群公告模态框确认按钮
        saveSendGroupNotice() {
            this.sendContent = this.$refs.sendChild.content
            this.selectList = this.$refs.sendChild.selectGroupList
            if (this.selectList.length == 0) {
                this.$Message.warning({
                    content: "请选择接收群",
                    background: true,
                })
                return
            }
            if (this.sendContent == "") {
                this.$Message.warning({
                    content: "公告内容不能为空",
                    background: true,
                })
                return
            } else {
                this.addBatchGroup()
            }
        },

        // 发群公告按钮点击事件
        sendGroupNotice() {
            this.sendGroupNoticeStatus = true
            this.$refs.sendChild.isTrue = false
            this.$refs.sendChild.content = ""
            this.$refs.sendChild.selectList = []
            this.$refs.sendChild.groupName = "点击这里选择群范围"
            let data = {
                pageSize: 20,
                page: 1,
            }
            this.$refs.sendChild.getList(data)
        },
        // 发模态框关闭事件
        sendGroupNoticeStatusfn(status) {
            if (!status && this.cancelsendGroupNoticeTip) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "您正在关闭此窗口，信息将不会保存，是否继续",
                    onOk: () => {
                        this.sendGroupNoticeStatus = status
                        this.$refs.sendChild.selectGroupList = []
                        this.$refs.sendChild.selectNameList = []
                    },
                })
            } else {
                this.sendGroupNoticeStatus = status
                this.cancelsendGroupNoticeTip = false
            }
        },
        // 修改沟通组模态框确认按钮
        saveEditGroup() {
            if (this.editData.name == "") {
                this.$Message.warning({
                    content: "群名称不能为空",
                    background: true,
                })
            } else {
                this.upOtherGroup()
            }
        },
        // 修改沟通组关闭模态框事件
        editGroupNumStatusfn(status) {
            if (!status && this.canceleditTip) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "您正在关闭此窗口，信息将不会保存，是否继续",
                    onOk: () => {
                        this.editGroupNumStatus = status
                    },
                })
            } else {
                this.editGroupNumStatus = status
                this.canceleditTip = false
            }
        },
        // 点击新增沟通组继续操作的新增管理员按钮
        addAdmin() {
            this.idAdmin = true
            let data = {
                page: 1,
                pageSize: 20,
            }
            this.userPage(data)
        },

        // 点击添加群成员弹窗的确认按钮按钮
        saveGroupeople() {
            this.addGroupNumStatus = false
        },
        // 添加群成员模态框关闭事件
        addGroupNumStatusfn(status) {
            if (!status && this.canceladdGroupTip) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "您正在关闭此窗口，信息将不会保存，是否继续",
                    onOk: () => {
                        this.addGroupNumStatus = status
                    },
                })
            } else {
                this.addGroupNumStatus = status
                this.canceladdGroupTip = false
            }
        },
        // 导入的方法们
        //确认导入
        submitExcel() {
            if (!this.excelFile) {
                this.$Notice.error({
                    title: "导入失败",
                    desc: "请选择需导入的文件",
                    duration: 3,
                })
                return
            }
            this.$Message.loading({
                content: "正在上传文件，请稍等...",
                duration: 0,
            })
            this.$refs.excelUpload.post(this.excelFile)
        },
        // 导入失败
        errorUpload() {
            this.$Message.destroy()
            this.$Notice.error({
                title: "导入失败",
                desc: "",
                duration: 3,
            })
        },
        //导入excel格式错误
        uploadFormatError(file) {
            this.$Message.destroy()
            this.$Notice.warning({
                title: "文件格式不正确",
                desc: "文件" + file.name + "格式不正确，请上传xls,xlsx,xlsb,xlsm或xlst格式的文件",
            })
        },
        //excel文件上传成功后执行
        uploadSuccess(res) {
            this.$Message.destroy()
            if (res.code == 200 && res.dataList.length == 0) {
                this.$Message.success({
                    background: true,
                    content: "上传成功！",
                })
                this.importStatus = false
                this.excelFile = null
                this.getList({
                    pageSize: this.pageSize,
                    page: this.curPage,
                })
            } else if (res.code == 200 && res.dataList.length > 0) {
                this.errorContent = ""
                res.dataList.map((item, index) => {
                    this.errorContent += `<div style="color:red">第${item.number + 2}行：${item.errorCode}</div>`
                })
                this.$Notice.success({
                    title: "数据导入失败，请排查以下问题后重新导入",
                    desc: this.errorContent,
                    duration: 3,
                })
            } else {
                this.$Notice.error({
                    title: "导入失败",
                    desc: res.desc,
                    duration: 3,
                })
            }
        },
        //上传之前
        beforeExcelUpload(file) {
            let memberFromScope = ""
            if (this.cityOper.city) {
                let data = this.cityOper.city.split("-")
                memberFromScope = data[data.length - 1]
                if (this.cityOper.region) {
                    let datas = this.cityOper.region.split("-")
                    memberFromScope = datas[datas.length - 1]
                    if (this.cityOper.stree) {
                        let datass = this.cityOper.stree.split("-")
                        memberFromScope = datass[datass.length - 1]
                        if (this.cityOper.project) {
                            let datasss = this.cityOper.project.split("-")
                            memberFromScope = datasss[datasss.length - 1]
                        }
                    }
                }
            }
            this.action = window.vue.getProxy()["/syaa"].target + "/upload/sy/user/chatUser/importUser"
            this.uploadData = {
                oemCode: parent.vue.oemInfo.oemCode,
                groupId: this.successData,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                staffName: parent.vue.loginInfo.userinfo.realName,
                memberFromScope: memberFromScope,
            }

            this.excelFile = file
            return false
        },
        // 导入群成员按钮
        importGroup(status) {
            this.importStatus = status
        },
        // 关闭继续操作模态框事件
        continueStatusFn(status) {
            this.continueStatus = status
        },
        // 点击新增沟通组按钮
        addGroupCode() {
            this.isEdit = false
            this.addGroupStatus = true
            this.addGroupLength = true
            this.isShow = false
            this.$nextTick(() => {
                this.isShow = true
            })
        },
        // 关闭添加/修改 沟通组模态框的事件
        addGroupStatusFn(status) {
            if (!status && this.canceladdTip) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "您正在关闭此窗口，信息将不会保存，是否继续",
                    onOk: () => {
                        this.formData = {
                            name: "",
                            type: "0",
                            user: "实名",
                            ischeck: "校验",
                            set: "",
                        }
                        this.cityOper = {
                            city: "",
                            region: "",
                            stree: "",
                            project: "",
                        }
                        this.addGroupStatus = status
                        this.isShow = false
                        this.$nextTick(() => {
                            this.isShow = true
                        })
                        // this.continueStatus = true;
                    },
                })
            } else {
                this.addGroupStatus = status
                this.canceladdTip = false
            }
        },
        // getProblem(value) {
        //   this.problem = value;

        // },
        // 新增/修改模态框的确定按钮
        saveGroup() {
            // 这是新增接口的方法
            let res = this.formData
            if (res.name == "") {
                this.$Message.warning({
                    content: "群名称不能为空",
                    background: true,
                })
                return
            }
            if (res.type == "0") {
                this.$Message.warning({
                    content: "请选择群类型",
                    background: true,
                })
                return
            }
            let configRos = []

            if (this.addGroupLength) {
                this.isShowCheck = true
                if (res.ischeck == "校验") {
                    if (res.set == "") {
                        this.$Message.warning({
                            content: "问题设置不能为空",
                            background: true,
                        })
                        return
                    } else {
                        configRos.push({
                            paraCode: this.problem[1].paraCode,
                            paraValue: res.set,
                        })
                    }
                } else {
                    configRos.push({ paraCode: this.problem[1].paraCode, paraValue: 1 })
                }
                if (res.user == "实名") {
                    configRos.push({ paraCode: this.problem[0].paraCode, paraValue: 1 })
                } else if (res.user == "网上家园认证") {
                    configRos.push({ paraCode: this.problem[0].paraCode, paraValue: 2 })
                } else {
                    configRos.push({ paraCode: this.problem[0].paraCode, paraValue: 0 })
                }
                let memberFromScope = ""
                if (this.cityOper.city) {
                    let data = this.cityOper.city.split("-")
                    memberFromScope = data[data.length - 1]
                    if (this.cityOper.region) {
                        let datas = this.cityOper.region.split("-")
                        memberFromScope = datas[datas.length - 1]
                        if (this.cityOper.stree) {
                            let datass = this.cityOper.stree.split("-")
                            memberFromScope = datass[datass.length - 1]
                            if (this.cityOper.project) {
                                let datasss = this.cityOper.project.split("-")
                                memberFromScope = datasss[datasss.length - 1]
                            }
                        }
                    }
                }
                let data = {
                    name: res.name,
                    type: res.type,
                    configRos: configRos,
                    memberFromScope: memberFromScope,
                }
                this.creatdGroup(data)
            } else {
                if (res.ischeck == "校验") {
                    if (res.set == "") {
                        this.$Message.warning({
                            content: "问题设置不能为空",
                            background: true,
                        })
                        return
                    } else {
                        configRos.push({
                            paraCode: this.problem[1].paraCode,
                            paraValue: res.set,
                        })
                    }
                } else {
                    configRos.push({ paraCode: this.problem[1].paraCode, paraValue: 1 })
                }
                if (res.user == "实名") {
                    configRos.push({ paraCode: this.problem[0].paraCode, paraValue: 1 })
                } else if (res.user == "网上家园认证") {
                    configRos.push({ paraCode: this.problem[0].paraCode, paraValue: 2 })
                } else {
                    configRos.push({ paraCode: this.problem[0].paraCode, paraValue: 0 })
                }
                let memberFromScope = ""
                if (this.cityOper.city) {
                    let data = this.cityOper.city.split("-")
                    memberFromScope = data[data.length - 1]
                    if (this.cityOper.region) {
                        let datas = this.cityOper.region.split("-")
                        memberFromScope = datas[datas.length - 1]
                        if (this.cityOper.stree) {
                            let datass = this.cityOper.stree.split("-")
                            memberFromScope = datass[datass.length - 1]
                            if (this.cityOper.project) {
                                let datasss = this.cityOper.project.split("-")
                                memberFromScope = datasss[datasss.length - 1]
                            }
                        }
                    }
                }
                // 这是修改接口的方法;
                let data = {
                    name: res.name,
                    type: res.type,
                    configRos: configRos,
                    memberFromScope: memberFromScope,
                    groupId: this.isGroupId,
                }
                this.updateGroup(data)
            }
        },
        // 修改沟通组的接口
        updateGroup(data) {
            this.$post(
                "/sendmsg/pc/chat/groupCreate/updateGroup",

                {
                    configRos: data.configRos,
                    groupId: data.groupId,
                    groupName: data.name,
                    groupType: data.type,
                    memberFromScope: data.memberFromScope,
                    oemCode: parent.vue.oemInfo.oemCode,
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                if (res.code == 200) {
                    this.addGroupStatus = false
                    this.formData = {
                        name: "",
                        type: "0",
                        user: "实名",
                        ischeck: "校验",
                        set: "",
                    }
                    this.cityOper = {
                        city: "",
                        region: "",
                        stree: "",
                        project: "",
                    }
                    this.$Message.success({
                        content: "修改沟通组成功",
                        background: true,
                    })
                    this.isShow = false
                    this.$nextTick(() => {
                        this.isShow = true
                    })
                    let data = {
                        pageSize: this.pageSize,
                        page: this.curPage,
                    }
                    this.getList(data)
                } else {
                    this.$Message.error({
                        content: "修改沟通组失败",
                        background: true,
                    })
                }
            })
        },
        // 动员群修改的接口
        upOtherGroup() {
            let params = {
                groupId: this.isGroupId,
                groupName: this.editData.name,
            }
            this.$post("/sendmsg/pc/chat/group/updateGroupName", params).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "修改成功",
                        background: true,
                    })
                    this.editData.name = ""
                    this.isGroupId = ""
                    this.editGroupNumStatus = false
                    let data = {
                        pageSize: this.pageSize,
                        page: this.curPage,
                    }
                    this.getList(data)
                    this.canceleditTip = false
                } else {
                    this.$Message.error({
                        content: "修改失败",
                        background: true,
                    })
                }
            })
        },
        // 新增的接口
        creatdGroup(data) {
            this.$post(
                "/sendmsg/pc/chat/groupCreate/createGroup",
                {
                    configRos: data.configRos,
                    createMode: "2",
                    creatorId: parent.vue.loginInfo.userinfo.custGlobalId,
                    groupName: data.name,
                    groupType: data.type,
                    memberFromScope: data.memberFromScope,
                    orgCode: this.rootData.orgCode,
                    oemCode: parent.vue.oemInfo.oemCode,
                    creatorName: parent.vue.loginInfo.userinfo.realName,
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                if (res.code == 200) {
                    this.successData = res.data
                    this.addGroupStatus = false
                    this.continueStatus = true
                    this.$Message.success({
                        content: "新增沟通组成功",
                        background: true,
                    })
                    this.formData = {
                        name: "",
                        type: "0",
                        user: "实名",
                        ischeck: "校验",
                        set: "",
                    }
                    this.cityOper = {
                        city: "",
                        region: "",
                        stree: "",
                        project: "",
                    }
                    this.isShow = false
                    this.$nextTick(() => {
                        this.isShow = true
                    })
                    let data = {
                        pageSize: this.pageSize,
                        page: this.curPage,
                    }
                    this.getList(data)
                } else {
                    this.$Message.error({
                        content: "新增沟通组失败",
                        background: true,
                    })
                }
            })
        },

        // 发送群公告
        sendGroup() {
            if (this.groupIds.length == 0) {
                this.$Message.error({
                    background: true,
                    content: "请选择接收的群范围",
                })
                return
            }
            if (!this.content) {
                this.$Message.error({
                    background: true,
                    content: "请填写公告内容",
                })
                return
            }
            this.$post("/sendmsg/pc/chat/group/addBatchGroupAffiche", {
                businessCode: "2",
                businessType: "2",
                content: this.content,
                groupIds: this.groupIds.join(","),
                oemCode: parent.vue.oemInfo.oemCode,
                creator: parent.vue.loginInfo.userinfo.realName,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            }).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        background: true,
                        content: "发送成功！",
                    })
                    this.cancelModalTip = false
                    this.sendGroupStatus = false
                } else {
                    this.sendError = true
                }
            })
        },
        addSendGroup() {
            this.$Message.loading({
                content: "正在获取数据，请稍等...",
                duration: 0,
            })
            this.$get("/sendmsg/pc/chat/group/getChatRegionTree", {
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: this.orgCode,
            })
                .then(res => {
                    this.$Message.destroy()
                    if (res.code == 200 && res.dataList.length > 0) {
                        this.groupTreeData = res.dataList
                        this.sendGroupStatus = true
                    } else if (res.code == 200 && res.dataList.length == 0) {
                        this.$Message.error({
                            background: true,
                            content: "暂未查询到您的群范围",
                        })
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    this.$Message.destroy()

                    this.$Message.error({
                        background: true,
                        content: "发送群公告功能异常，请联系管理员处理",
                    })
                })
        },
        sendGroupStatusFn(status) {
            if (!status && this.cancelModalTip) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "您正在关闭此窗口，信息将不会保存，是否继续",
                    onOk: () => {
                        this.sendGroupStatus = status
                        this.content = ""
                        this.groupIds = []
                    },
                })
            } else if (!status && !this.cancelModalTip) {
                this.sendGroupStatus = status
                this.content = ""
                this.groupIds = []
            } else {
                this.sendGroupStatus = status
            }
        },
        // 单次选中群范围
        oneSelectGroupTree(arr) {
            this.groupIds = []
            this.selectGroupTree(arr)
        },
        // 全选，单次选中时，push到regionIds
        selectGroupTree(arr) {
            arr.map(item => {
                if (!item.children || item.children.length == 0) {
                    this.groupIds.push(item.groupId)
                    this.groupIds = Array.from(new Set(this.groupIds))
                } else {
                    this.selectGroupTree(item.children)
                }
            })
        },
        // 封装全选和反选群范围
        allDeleteSelectGroup(tree, checkVal, expandVal) {
            const result = []
            tree.forEach(item => {
                let id = item.id
                let groupId = item.groupId
                let parentId = item.parentId
                let expand = expandVal !== "" ? expandVal : item.expand
                let checked = checkVal !== "" ? checkVal : item.checked
                let title = item.title
                let code = item.code
                let children = item.children
                // 如果有子节点，递归
                if (children && children.length != 0) {
                    children = this.allDeleteSelectGroup(children, checkVal, expandVal)
                }
                result.push({
                    expand,
                    code,
                    checked,
                    title,
                    children,
                    id,
                    parentId,
                    groupId,
                })
            })
            return result
        },
        // 全选群范围
        allSelectGroup() {
            this.groupIds = []
            this.groupTreeData = this.allDeleteSelectGroup(this.groupTreeData, true, "")
            this.selectGroupTree(this.groupTreeData)
        },
        // 反选群范围
        deleteSelectGroup() {
            this.groupTreeData = this.allDeleteSelectGroup(this.groupTreeData, false, "")
            this.groupIds = []
        },
        expandSelectGroup() {
            this.groupTreeData = this.allDeleteSelectGroup(this.groupTreeData, "", true)
        },
        shrinkSelectGroup() {
            this.groupTreeData = this.allDeleteSelectGroup(this.groupTreeData, "", false)
        },
        // 群二维码
        officialStatusFn(status) {
            this.officialStatus = status
        },
        // 合并生产海报并下载
        mergeImage() {
            html2canvas(this.$refs.haibaoImg, { useCORS: true }).then(function(canvas) {
                let src = canvas.toDataURL()
                var image = new Image()

                // 解决跨域 Canvas 污染问题
                image.setAttribute("crossOrigin", "anonymous")
                image.onload = function() {
                    canvas.width = image.width
                    canvas.height = image.height
                    var context = canvas.getContext("2d")
                    context.drawImage(image, 0, 0, image.width, image.height)
                    var url = canvas.toDataURL("image/png", 1.0)
                    var a = document.createElement("a")
                    var event = new MouseEvent("click")
                    a.download = "群推广宣传二维码"
                    a.href = url
                    a.dispatchEvent(event)
                }
                image.src = src
            })
        },
        async openOfficial(code) {
            this.$Message.loading({
                content: "正在加载数据，请稍等...",
                duration: 0,
            })
            if (code.communityName) {
                if (code.communityName.indexOf("社区") > -1) {
                    this.communityName = code.communityName + "居委会"
                } else {
                    this.communityName = code.communityName + "委会"
                }
            }
            let projectRes = await this.$get("/gx/pc/organization/coverProjectCodeByOrgCode", {
                orgCode: code.memberFromScope ? code.memberFromScope.split("-")[code.memberFromScope.split("-").length - 1] : "",
            })
            let projectCode = projectRes.data
            let type = ""
            let params = {}
            type = "COMMON_GROUP"

            if (code.memberFromScope && code.memberFromScope != "") {
                let arr = code.memberFromScope.split("-")
                if (arr.length == 2) {
                    params = {
                        groupId: code.groupId,
                        cityCode: arr[arr.length - 1],
                    }
                } else if (arr.length == 3) {
                    params = {
                        groupId: code.groupId,
                        regionCode: arr[arr.length - 1],
                    }
                } else if (arr.length == 4) {
                    params = {
                        groupId: code.groupId,
                        streetCode: arr[arr.length - 1],
                        streetName: code.communityName,
                    }
                } else if (arr.length == 5) {
                    params = {
                        groupId: code.groupId,
                        // groupType:code.groupType,
                        projectName: code.communityName,
                        projectCode: projectCode,
                    }
                }
            }

            // params = {
            //   groupId: code.groupId,
            //   projectCode,
            //   groupType: code.groupType
            // };
            this.$post(
                "/datamsg/api/pc/wxqr/createWxAppletQr",
                {
                    generateType: "1",
                    width: 200,
                    type: type,
                    value: JSON.stringify({
                        path: "pages/welcome/welcome",
                        redirectPath: "/mainTaskPages/modules/onlineHome/pages/groupAttendInfoEntry/index",
                        params: params,
                    }),
                    appletCode: "suiyue",
                },
                { "Content-Type": "application/json" }
            )
                .then(res => {
                    this.$Message.destroy()
                    if (res.code == 200) {
                        this.officialUrl = "pages/welcome/welcome?scene=" + res.data.code
                        this.codeImgUrl = res.data.codeUrl
                        this.officialStatus = true
                        this.noteCode = res.data.code
                        // this.$post(
                        //   "/verifyapi/api/sytop/pc/urlLink/createUrlLink",
                        //   {
                        //     path:'pages/welcome/welcome',
                        //     query: `scene=${this.noteCode}`,
                        //   },
                        // ).then(res => {
                        //   if (res.code == 200 && res.data) {
                        //     this.noteUrl = res.data.link
                        //   }
                        // });
                    } else {
                        this.$Message.error({
                            background: true,
                            content: "公众号链接生成失败！请联系管理员解决",
                        })
                    }
                })
                .catch(err => {
                    this.$Message.destroy()
                    this.$Message.error({
                        background: true,
                        content: "获取群二维码失败，请联系管理员处理",
                    })
                })
        },
        downloadImgCode() {
            var image = new Image()
            // 解决跨域 Canvas 污染问题
            image.setAttribute("crossOrigin", "anonymous")
            image.onload = function() {
                var canvas = document.createElement("canvas")
                canvas.width = image.width
                canvas.height = image.height
                var context = canvas.getContext("2d")
                context.drawImage(image, 0, 0, image.width, image.height)
                var url = canvas.toDataURL("image/png") //得到图片的base64编码数据
                var a = document.createElement("a") // 生成一个a元素
                var event = new MouseEvent("click") // 创建一个单击事件
                a.download = "群推广二维码"
                a.href = url // 将生成的URL设置为a.href属性
                a.dispatchEvent(event) // 触发a的单击事件
            }
            image.src = this.codeImgUrl
        },
        copyShareCode() {
            var oInput = document.createElement("input")
            oInput.value = this.officialUrl
            document.body.appendChild(oInput)
            oInput.select()
            document.execCommand("Copy")
            oInput.className = "oInput"
            oInput.style.display = "none"
            document.body.removeChild(oInput)
            this.$Message.success({
                background: true,
                content: "复制链接成功！",
            })
        },
        // 点击复制短信链接
        copyNoteUrl() {
            var oInput = document.createElement("input")
            oInput.value = this.noteUrl
            document.body.appendChild(oInput)
            oInput.select()
            document.execCommand("Copy")
            oInput.className = "oInput"
            oInput.style.display = "none"
            document.body.removeChild(oInput)
            this.$Message.success({
                background: true,
                content: "复制链接成功！",
            })
        },
        //导出excel
        exportExcel() {
            this.$Modal.confirm({
                title: "温馨提示",
                content: "<p>您正在导出当前页的数据，是否继续？（若要导出更多数据，请设置每页条数。）</p>",
                onOk: () => {
                    let tHeader = ["群范围", "群名称", "群成员数", "创建时间", "群类型", "群管理员", "消息数"]
                    let filterVal = ["scopeName", "groupName", "memberCount", "gmtCreate", "groupType", "chatGroupManager", "chatRecordCount"]
                    let tableData = JSON.parse(JSON.stringify(this.tableData))
                    this.$core.exportExcel(tHeader, filterVal, tableData, "沟通组管理列表")
                },
            })
        },
        getQueryConfig() {
            this.$post("/sendmsg/pc/chat/groupCreate/queryConfigList", {
                businessType: 1,
                oemCode: parent.vue.oemInfo.oemCode,
            }).then(res => {
                let data = res.dataList
                if (data) {
                    data.forEach(item => {
                        if (item.paraCode == "REAL_NAME") {
                            this.problem[0] = {
                                paraCode: "REAL_NAME",
                                paraValue: "",
                            }
                        }
                        if (item.paraCode == "CHECK_QUESTION") {
                            this.problem[1] = {
                                paraCode: "CHECK_QUESTION",
                                paraValue: "",
                            }
                        }
                    })
                }
                // this.problem = res.dataList;
            })
        },
        // 重置
        rest() {
            this.searchData = {}
            let data = {
                page: 1,
                pageSize: 20,
            }
            this.getList(data)
        },
        //菜单树
        async getList(data) {
            this.tableData = []
            this.loading = true
            await this.$get("/sendmsg/pc/chat/groupCreate/queryPage", {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                // groupName: this.searchData.groupName,
                groupType: this.$route.query.groupType,
                orgCode: this.rootData.orgCode,
                oemCode: parent.vue.oemInfo.oemCode,
                pageSize: data.pageSize,
                page: data.page,
            })
                .then(res => {
                    if (res.code === "200") {
                        res.dataList.map((item, index, arr) => {
                            arr[index].gmtCreate = format(item.gmtCreate, "yyyy-MM-dd HH:mm:ss")
                            this.groupList.map(items => {
                                if (item.groupType == items.value) {
                                    item.groupTypeName = items.label
                                }
                            })

                            // item.groupType =
                            //   item.groupType == "1"
                            //     ? "动员群"
                            //     : item.groupType == "3"
                            //     ? "党员群"
                            //     : item.groupType == "4"
                            //     ? "居民代表群"
                            //     : item.groupType == "5"
                            //     ? "退役军人群"
                            //     : item.groupType == "6"
                            //     ? "楼长群"
                            //     : item.groupType == "7"
                            //     ? "残疾人群"
                            //     : item.groupType == "8"
                            //     ? "楼栋群"
                            //     : item.groupType == "9"
                            //     ? "街巷群"
                            //     : item.groupType == "10"
                            //     ? "其他"
                            //     : item.groupType == "11"
                            //     ? "内部管理群"
                            //     : item.groupType == "12"
                            //     ? "志愿者群"
                            //     : item.groupType == "13"
                            //     ? "网格群"
                            //     : item.groupType == "14"
                            //     ? "小区群"
                            //     : item.groupType == "15"
                            //     ? "第一沟通组"
                            //     : item.groupType == "16"
                            //     ? "第二沟通组"
                            //     : item.groupType == "17"
                            //     ? "第三沟通组" :''
                            // : item.groupType == "18"
                            // ? "业委会群"
                            // : item.groupType == "19"
                            // ? "物业管理群"
                            // : item.groupType == "20"
                            // ? "社区卫生站群"
                            // : item.groupType == "21"
                            // ? "社工站群"
                            // : item.groupType == "22"
                            // ? "属地企业群"
                            // : item.groupType == "23"
                            // ? "属地单位群"
                            // : item.groupType == "24"
                            // ? "公安派出所群"
                            // : item.groupType == "25"
                            // ? "网格员群"
                            // :''
                            item.scopeName = item.scopeName.replace(/\//g, "")
                            let list = []
                            item.chatGroupManager.map(item => {
                                if (item.manager != null) {
                                    list.push(item.manager)
                                }
                            })
                            item.chatGroupManager = list.join(",")
                        })
                        this.spinShow = false
                        this.tableData = res.dataList
                        this.total = res.maxCount
                        this.curPage = res.currentPage
                        this.treeParams.name = ""
                        this.treeParams.phone = ""
                        this.pageSize = res.pageSize
                        this.loading = false
                    } else {
                        this.loading = false
                        this.$Message.error({
                            background: true,
                            content: "数据获取失败",
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },
        //分页器
        hadlePageSize(obj) {
            this.getList(obj)
        },
        // 外部负责人弹窗状态变化
        outsideModalFn(status) {
            if (!status) {
                //关闭弹窗更新表格数据
                this.getList({
                    pageSize: this.pageSize,
                    page: this.curPage,
                })
            }
            this.outsideModal = status
            this.idAdmin = false
        },
        // 发言管理弹窗状态变化
        msgManageModalFn(status) {
            this.msgManageModal = status
        },
        //全选
        allSelectManager() {
            let manageStatusArr = []
            this.manageList.map((item, index, arr) => {
                arr[index].status = true
                manageStatusArr.push({
                    manager: item.manage,
                    managerId: item.manageId,
                })
            })
            this.$post(
                "/sendmsg/pc/chat/groupmanager/addManagerBatch",
                {
                    chatManagerAddRoList: manageStatusArr,
                    groupId: this.groupId,
                    oemCode: parent.vue.loginInfo.userinfo.oemCode,
                    operCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                    staffName: parent.vue.loginInfo.userinfo.realName,
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                if (res.code == 200) {
                    this.$Message["success"]({
                        background: true,
                        content: "新增全部成功！",
                    })
                } else {
                    this.$Message["error"]({
                        background: true,
                        content: "新增失败！",
                    })
                }
            })
        },
        //多选框勾选取消
        checkboxChange(data) {
            if (data.status) {
                //新增
                let params = {
                    groupId: this.groupId,
                    manager: data.manage,
                    managerId: data.manageId,
                    oemCode: parent.vue.loginInfo.userinfo.oemCode,
                    operCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                    staffName: parent.vue.loginInfo.userinfo.realName,
                }
                this.$post("/sendmsg/pc/chat/groupmanager/addManager", params).then(res => {
                    if (res.code == 200) {
                        this.$Message["success"]({
                            background: true,
                            content: "新增成功！",
                        })
                    } else {
                        this.$Message["error"]({
                            background: true,
                            content: "新增失败！",
                        })
                    }
                })
            } else {
                //删除
                let params = {
                    groupId: this.groupId,
                    custGlobalId: data.manageId,
                    operCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                    staffName: parent.vue.loginInfo.userinfo.realName,
                }
                this.$post("/sendmsg//pc/chat/groupmanager/delManager", params).then(res => {
                    if (res.code == 200) {
                        this.$Message["success"]({
                            background: true,
                            content: "删除成功！",
                        })
                    } else {
                        this.$Message["error"]({
                            background: true,
                            content: "删除失败！",
                        })
                    }
                })
            }
        },
        //分页
        getUserPage(data) {
            this.userPage({
                page: data,
                pageSize: this.userpageSize,
            })
        },
        // 获取详细信息接口
        getUpdateMessage(data) {
            this.$get("/sendmsg/pc/chat/groupCreate/queryGroupDetails", {
                groupId: data,
            }).then(res => {
                let data = res.data
                if (data.configVoList.length != 0) {
                    if (data.configVoList[0].paraName == "校验问题") {
                        if (data.configVoList[0].paraValue == "0") {
                            this.formData.ischeck = "不校验"
                            this.formData.set = ""
                            this.isShowCheck = false
                        } else {
                            this.formData.ischeck = "校验"
                            this.formData.set = data.configVoList[0].paraValue
                            this.isShowCheck = true
                        }
                    }

                    if (data.configVoList[1].paraName == "实名加入") {
                        if (data.configVoList[1].paraValue == "0") {
                            this.formData.user = "不认证"
                        } else if (data.configVoList[1].paraValue == "2") {
                            this.formData.user = "网上家园认证"
                        } else {
                            this.formData.user = "实名"
                        }
                    }
                } else {
                    this.formData.ischeck = "不校验"
                    this.formData.set = ""
                    this.isShowCheck = false
                    this.formData.user = "不认证"
                }

                this.formData.name = data.groupName
                this.formData.type = data.groupType
                this.memberFromScope = ""
                this.memberFromScope = data.memberFromScope
            })
        },
        //发言管理
        goMsg(data) {
            let str = ""
            let List = []
            List = data.chatGroupManager.split(",")
            List.map((item, index) => {
                if (index != data.chatGroupManager.length - 1) {
                    str += item.manager + "、"
                } else {
                    str += item.manager
                }
            })
            this.msgManageData = data
            this.msgManageData.manager = data.chatGroupManager
            this.msgManageModal = true
        },
        selectManagerByGroupId(data) {
            if (data.groupId) {
                this.$get("/sendmsg/pc/chat/groupmanager/selectManagerByGroupId", {
                    groupId: data.groupId,
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                    staffName: parent.vue.loginInfo.userinfo.realName,
                })
                    .then(res => {
                        if (res.code === "200") {
                            this.managerIdList = res.dataList
                        } else {
                            this.$Message.error({
                                background: true,
                                content: res.desc,
                            })
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                this.$Message.error("无groupId")
            }
        },
        //userPage分页发生改变
        userPage(obj) {
            if (this.idAdmin) {
                this.groupId = this.successData
            }
            this.$Message.loading({
                content: "正在加载数据，请稍等...",
                duration: 0,
            })
            let params = {
                groupId: this.groupId,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: this.rootData.orgCode,
                staffId: this.rootData.id,
                page: obj.page,
                pageSize: obj.pageSize,
            }
            this.$get("/sendmsg/pc/chat/groupmanager/selectManagerStaffPage", params)
                .then(res => {
                    this.$Message.destroy()
                    if (res.code === "200") {
                        this.manageList = res.dataList
                        this.usercurPage = res.currentPage
                        this.userpageSize = res.pageSize
                        this.usertotal = res.maxCount
                        this.outsideModal = true
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    this.$Message.destroy()
                    console.log(err)
                    this.$Message.error({
                        backgroundtrue,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        },
        userMsg(data) {
            if (data) {
                this.groupId = data.groupId
            }
            this.usercurPage = 1
            this.userpageSize = 20
            this.userPage({
                page: this.usercurPage,
                pageSize: this.userpageSize,
            })
        },
        //联络人管理
        goContact(params) {
            let Base64 = require("js-base64").Base64
            this.$router.push({
                path: "/groupcontacter",
                query: {
                    data: Base64.encode(JSON.stringify(params.row)),
                    groupId: params.row.groupId,
                    isAdd: true,
                    orgCode: params.row.orgCode,
                    groupType: this.$route.query.groupType,
                },
            })
        },
        goRouter(data, params) {
            if (data != "") {
                let Base64 = require("js-base64").Base64
                this.$router.push({
                    path: "/groupcontacter",
                    query: {
                        data: Base64.encode(JSON.stringify(params.row)),
                        problem: data,
                    },
                })
            } else {
                let Base64 = require("js-base64").Base64
                this.$router.push({
                    path: "/groupcontacter",
                    query: {
                        data: Base64.encode(JSON.stringify(params.row)),
                    },
                })
            }
        },
        async getSelect() {
            await this.$get("/dictlist/api/lfcommon/pc/dict/selectDictDataList", {
                dictType: "CHAT_GROUP_TYPE",
                appCode: "",
            }).then(res => {
                let data = res.dataList
                this.groupList = []
                data.map(item => {
                    // if (item.groupType != "1") {
                    this.groupList.push({
                        value: item.dictKey,
                        label: item.dictValue,
                    })
                    // }
                })
            })
        },
        addBatchGroup() {
            this.$post("/sendmsg/pc/chat/group/addBatchGroupAffiche", {
                businessCode: 2,
                businessType: 2,
                content: this.sendContent,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                fileUrl: "",
                groupIds: this.selectList,
                houseNum: "",
                oemCode: parent.vue.oemInfo.oemCode,
                remark: "",
                creator: parent.vue.loginInfo.userinfo.realName,
            }).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "新增群公告成功",
                        background: true,
                    })
                    this.sendGroupNoticeStatus = false
                    this.cancelsendGroupNoticeTip = false
                    this.$refs.sendChild.selectGroupList = []
                    this.$refs.sendChild.selectNameList = []
                } else {
                    this.$Message.error({
                        content: "新增群公告失败",
                        background: true,
                    })
                }
            })
        },
        updateGroupGrid(data) {
            this.$post(
                "/sendmsg/pc/chat/grid/updateGroupGrid",
                {
                    groupId: data.groupId,
                    groupType: data.groupType,
                    groupName: data.groupName,
                    oemCode: parent.vue.oemInfo.oemCode,
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "修改成功",
                        background: true,
                    })
                    this.editGroup.status = false
                    this.editGroup.tip = false
                    this.isStree = false
                    let data = {
                        pageSize: this.pageSize,
                        page: this.curPage,
                    }
                    this.getList(data)
                    return
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },
        // 同步群成员
        recordMember(data) {
            this.$Message.loading({
                content: "正在同步中",
            })
            this.$post("/sendmsg/pc/chat/group/onlineHomeJoinChat", {
                groupId: data,
            }).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "同步成功",
                        background: true,
                    })
                    let data = {
                        pageSize: this.pageSize,
                        page: this.curPage,
                    }
                    this.getList(data)
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },
    },
    async created() {
        this.rootData = parent.vue.loginInfo.userinfo
        let data = {
            page: 1,
            pageSize: 20,
        }
        await this.getSelect()
        await this.getList(data)
        this.getQueryConfig()
    },
    filters: {
        spliceTitle(option) {
            if (option.length > 22) {
                return (option = option.slice(0, 22) + "...")
            } else {
                return option
            }
        },
    },
    components: {
        LiefengContent,
        LiefengModal,
        LiefengTable,
        LiefengTree,
        MsgManage,
        Addgroup,
        Addgroupeople,
        Sendnotice,
        AddQrcode,
    },
}
</script>

<style scoped lang="less">
.qrcode {
    position: relative;
    width: 400px;
    height: 565px;
    background: url("/images/pic_hd.jpg") no-repeat;
    background-size: 100% 100%;
    img {
        position: absolute;
        top: 256px;
        left: 83px;
        width: 230px;
    }
    .h1h3 {
        width: 100%;
        position: absolute;
        top: 75px;
        text-align: center;
    }
    h1 {
        text-align: center;
        font-size: 32px;
        color: #c62e23;
        margin-bottom: 10px;
    }
    h3 {
        padding: 0 30px;
        text-align: center;
        font-size: 26px;
        color: #c62e23;
    }
    p {
        width: 100%;
        position: absolute;
        top: 223px;
        text-align: center;
        font-size: 16px;
        color: #000;
    }
    span {
        width: 100%;
        position: absolute;
        text-align: center;
        top: 488px;
        font-size: 17px;
        color: #000;
    }
}
.group {
    /deep/.ivu-modal-close {
        display: none;
    }
}
</style>
